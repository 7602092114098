import _kaiju from './kaiju.png';
import _original from './products/original.png';
import _signaturePotato from './products/signature-potato.png';
import _ramyeon from './products/ramyeon.png';
import _mozzacocoreo from './products/mozzacocoreo.png';
import _cornflakes from './products/cornflakes.png';
import _honeyCornflakes from './products/honey-cornflakes.png';
import _spicy from './products/spicy.png';
import _logo from './logo.png';

export const kaiju = _kaiju;
export const logo = _logo;
export const products = {
  original: _original,
  signaturePotato: _signaturePotato,
  ramyeon: _ramyeon,
  mozzacocoreo: _mozzacocoreo,
  cornflakes: _cornflakes,
  honeyCornflakes: _honeyCornflakes,
  spicy: _spicy,
};
