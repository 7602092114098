import React from 'react';
import { List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Products from '../assets/data/products';
import PageLayout from '../components/PageLayout';

const styles = {
  list: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    overflowX: 'scroll',
  },
  listItem: {
    overflow: 'visible',
  },
  corndog: {
    height: 300,
  },
};

const useStyles = makeStyles(styles);

const MenuItem = ({ item: { image } }) => {
  const classes = useStyles();
  const listItemClasses = classNames({
    [classes.listItem]: true,
  });
  const corndogClasses = classNames({
    [classes.corndog]: true,
  });
  return (
    <ListItem className={listItemClasses}>
      <img src={image} alt="logo" className={corndogClasses} />
    </ListItem>
  );
};

const Menu = () => {
  const classes = useStyles();
  const listClasses = classNames({
    [classes.list]: true,
  });
  return (
    <PageLayout title="Our menu">
      <List className={listClasses}>
        {Products.map((e, i) => <MenuItem key={i} item={e} />)}
      </List>
    </PageLayout>
  );
};

export default Menu;
