import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { defaultFont } from '../assets/theme';

const styles = (theme) => ({
  text: {
    ...defaultFont,
    color: 'white',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '2em',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem',
    },
  },
  description: {
    height: '70%',
    width: '80%',
    margin: '20px auto',
  },
});

const useStyles = makeStyles(styles);

const AprilFools = () => {
  const classes = useStyles();
  const titleClasses = classNames({
    [classes.text]: true,
    [classes.title]: true,
  });
  const descriptionClasses = classNames({
    [classes.description]: true,
  });
  const textClasses = classNames({
    [classes.text]: true,
  });
  return (
    <div style={{ height: '100%' }}>
      <Typography className={titleClasses}>Happy<br />April Fool's Day! 😉</Typography>
      <div className={descriptionClasses}>
        <Typography className={textClasses}>Sorry for the prank guys. We want you to know that we love you and we really miss you. ❤️</Typography>
        <Typography className={textClasses}>Stay tuned for more promotions and new flavours coming soon. We cant wait to see you after the MCO.</Typography>
      </div>
      <Typography className={textClasses}>#stayhealthy #dudukrumah</Typography>
    </div>
  );
};

export default AprilFools;
