import React from 'react';
import { Typography, IconButton } from '@material-ui/core';
import { Room } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { defaultFont } from '../assets/theme';
import PageLayout from '../components/PageLayout';

const styles = {
  text: {
    ...defaultFont,
    color: 'white',
    textAlign: 'center',
  },
  address: {
    margin: '3%',
  },
};

const useStyles = makeStyles(styles);

const Outlets = () => {
  const classes = useStyles();
  const textClasses = classNames({
    [classes.text]: true,
  });
  const addressClasses = classNames({
    [classes.text]: true,
    [classes.address]: true,
  });
  return (
    <PageLayout title="Our outlets">
      <Typography variant="h4" className={textClasses}>Giant Batu Cave, Selayang</Typography>
      <Typography variant="h5" className={addressClasses}>
        Lot FK06, Giant Hypermarket Batu Cave,
        <br />Lot 10243 Jalan Batu Cave
        <br />Bandar Selayang Gombak Selangor.
      </Typography>
      <IconButton aria-label="gps" href="https://goo.gl/maps/Ndh4PUn5DpU1gRFf8" target="_blank">
        <Room fontSize="large" />
      </IconButton>
    </PageLayout>
  );
};

export default Outlets;
