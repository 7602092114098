import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { defaultFont } from '../assets/theme';
import { logo } from '../assets/images';
import PageLayout from '../components/PageLayout';

const styles = {
  image: {
    maxHeight: 250,
    maxWidth: '80%',
  },
  text: {
    ...defaultFont,
    color: 'white',
    textAlign: 'center',
  },
  description: {
    margin: '2%',
  },
};

const useStyles = makeStyles(styles);

const Halal = () => {
  const classes = useStyles();
  const imageClasses = classNames({
    [classes.image]: true,
  });
  const textClasses = classNames({
    [classes.text]: true,
  });
  const descriptionClasses = classNames({
    [classes.text]: true,
    [classes.description]: true,
  });
  return (
    <PageLayout title="Our halal commitment" verticalScrollable>
      <Grid container spacing={3}>
        <Grid container item lg={4} justify="center" alignItems="center">
          <img src={logo} alt="logo" className={imageClasses} />
        </Grid>
        <Grid item lg={8} sm={12}>
          <Typography variant="h4" className={textClasses}>
            Halal is always our top priority.
          </Typography>
          <Typography variant="h5" className={descriptionClasses}>
            We are in the process of getting certified by JAKIM as completely compliant to Syariah requirements.
            In the meantime, we always ensure all of our ingredients are certified halal and there will always be muslim staff working at the kitchen at all times.
          </Typography>
          <Typography variant="h4" className={textClasses}>
            Selamat menjamu selera di KaijuCrunch!
          </Typography>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default Halal;
