import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Container from '@material-ui/core/Container';

import Parallax from './components/Parallax';
import Header from './components/Header';
import HeaderLinks from './components/HeaderLinks';

import Home from './scenes/Home';
import Menu from './scenes/Menu';
import Outlets from './scenes/Outlets';
import Halal from './scenes/Halal';
import AprilFools from './scenes/AprilFools';

class App extends Component {
  render() {
    return (
      <div>
        <Header
          color="transparent"
          brand="kaijucrunch"
          fixed
          rightLinks={<HeaderLinks />}
          changeColorOnScroll={{
            height: 200,
            color: 'white',
          }}
        />
        <Parallax backgroundColor="#FCC314">
          <Container style={{ height: '70vh' }}>
            <Routes />
          </Container>
        </Parallax>
      </div>
    );
  }
}

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route path="/order-delivery/brown-sugar-boba-corndog" component={AprilFools} />
        <Route path="/halal" component={Halal} />
        <Route path="/outlets" component={Outlets} />
        <Route path="/menu" component={Menu} />
        <Route path="/" component={Home} />
      </Switch>
    );
  }
}

export default App;
