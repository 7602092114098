import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { logo } from '../assets/images';
import { defaultFont } from '../assets/theme';

const styles = {
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    maxWidth: '80%',
    maxHeight: '60%',
    margin: 'auto',
  },
  tagline: {
    ...defaultFont,
    color: 'white',
    textAlign: 'center',
  },
};

const useStyles = makeStyles(styles);

const Home = () => {
  const classes = useStyles();
  const containerClasses = classNames({
    [classes.container]: true,
  });
  const logoClasses = classNames({
    [classes.logo]: true,
  });
  const taglineClasses = classNames({
    [classes.tagline]: true,
  });
  return (
    <div className={containerClasses}>
      <img src={logo} alt="logo" className={logoClasses} />
      <Typography variant="h3" className={taglineClasses}>Korean street food cheese corndog</Typography>
    </div>
  );
};

export default Home;
