import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { defaultFont } from '../assets/theme';

const styles = {
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    ...defaultFont,
    color: 'white',
    textAlign: 'center',
    margin: '3%',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  verticalScrollable: {
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  horizontalScrollable: {
    overflowX: 'scroll',
    overflowY: 'hidden',
  },
};

const useStyles = makeStyles(styles);

const PageLayout = (props) => {
  const {
    children, title, verticalScrollable, horizontalScrollable,
  } = props;
  const classes = useStyles();
  const pageLayoutClasses = classNames({
    [classes.container]: true,
  });
  const titleClasses = classNames({
    [classes.title]: true,
  });
  const bodyClasses = classNames({
    [classes.body]: true,
    [classes.verticalScrollable]: verticalScrollable,
    [classes.horizontalScrollable]: horizontalScrollable,
  });
  return (
    <div className={pageLayoutClasses}>
      <Typography variant="h2" className={titleClasses}>{title}</Typography>
      <div className={bodyClasses}>{children}</div>
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  verticalScrollable: PropTypes.bool,
  horizontalScrollable: PropTypes.bool,
};

export default PageLayout;
