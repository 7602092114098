import { products } from '../images';

export default [
  {
    name: 'Kaiju Sausage',
    image: products.original,
  },
  {
    name: 'Original MozaSausage',
    image: products.original,
  },
  {
    name: 'Signature Potato MozaSausage',
    image: products.signaturePotato,
  },
  {
    name: 'Ramyeon MozaSausage',
    image: products.ramyeon,
  },
  {
    name: 'Cornflake MozaSausage',
    image: products.cornflakes,
  },
  {
    name: 'Spicy Korean Moza',
    image: products.spicy,
  },
  {
    name: 'Mozacocoreo',
    image: products.mozzacocoreo,
  },
  {
    name: 'Honey Cornflakes',
    image: products.honeyCornflakes,
  },
];
