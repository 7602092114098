import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, List, ListItem } from '@material-ui/core';
import { Instagram } from '@material-ui/icons';

import { defaultFont } from '../assets/theme';
import Button from './Button';

const styles = (theme) => ({
  list: {
    ...defaultFont,
    fontSize: '14px',
    margin: 0,
    paddingLeft: '0',
    listStyle: 'none',
    paddingTop: '0',
    paddingBottom: '0',
    color: 'inherit',
  },
  listItem: {
    float: 'left',
    color: 'inherit',
    position: 'relative',
    display: 'block',
    width: 'auto',
    margin: '0',
    padding: '0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '&:after': {
        width: 'calc(100% - 30px)',
        content: '""',
        display: 'block',
        height: '1px',
        marginLeft: '15px',
        backgroundColor: '#e5e5e5',
      },
    },
  },
  listItemText: {
    padding: '0 !important',
  },
  navLink: {
    ...defaultFont,
    color: 'inherit',
    position: 'relative',
    padding: '0.9375rem',
    fontWeight: '400',
    fontSize: '1.5rem',
    textTransform: 'uppercase',
    borderRadius: '3px',
    lineHeight: '20px',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex',
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'rgba(200, 200, 200, 0.2)',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 30px)',
      marginLeft: '15px',
      marginBottom: '8px',
      marginTop: '8px',
      textAlign: 'left',
      '& > span:first-child': {
        justifyContent: 'flex-start',
      },
    },
  },
  notificationNavLink: {
    color: 'inherit',
    padding: '0.9375rem',
    fontWeight: '400',
    fontSize: '12px',
    textTransform: 'uppercase',
    lineHeight: '20px',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex',
    top: '4px',
  },
  registerNavLink: {
    top: '3px',
    position: 'relative',
    fontWeight: '400',
    fontSize: '12px',
    textTransform: 'uppercase',
    lineHeight: '20px',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex',
  },
  navLinkActive: {
    color: 'inherit',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  icons: {
    width: '20px',
    height: '20px',
    marginRight: '3px',
  },
  socialIcons: {
    position: 'relative',
    fontSize: '1.3rem !important',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      color: 'black',
    },
  },
  dropdownLink: {
    '&,&:hover,&:focus': {
      color: 'inherit',
      textDecoration: 'none',
      display: 'block',
      padding: '10px 20px',
    },
  },
  marginRight5: {
    marginRight: '5px',
  },
});

const useStyles = makeStyles(styles);

const HeaderLinks = (props) => {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          href="/menu"
          color="transparent"
          className={classes.navLink}
        >
          Menu
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/outlets"
          color="transparent"
          className={classes.navLink}
        >
          Outlets
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/halal"
          color="transparent"
          className={classes.navLink}
        >
          Halal
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <IconButton
          href="https://www.instagram.com/kaijucrunch.my/"
          target="_blank"
          className={classes.navLink}
        >
          <Instagram className={classes.socialIcons} />
        </IconButton>
      </ListItem>
    </List>
  );
};

export default HeaderLinks;
